
import DashboardGrid from '@/components/DashboardGrid.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SummaryTab',
  components: {
    DashboardGrid,
  },
});
